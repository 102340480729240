import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Swiper from 'react-id-swiper'
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import CSSPlugin from 'gsap/CSSPlugin';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { TimelineLite } from "gsap/all"

const C = CSSPlugin;

const ArticleYearSlider = (props) => {
    const [swiper1, updateSwiper1] = useState(null);
    // var activeYearKey;
    useEffect(() => {
        if(swiper1 !== null){
            if (typeof window !== `undefined`) {
                // setTimeout(function(){
                //     $('html, body').animate({scrollTop:0}, 1000, function(){
                //         // Sticky Sidebar
                //         if (window.matchMedia("(min-width: 992px)").matches) {
                //             // var SingleArticleOffsetTop;
                //             // var SingleArticleYearTimelineOffsetTop = $(".single-article-year-timeline").offset().top;
                //             var SingleArticleYearTimelineOffsetLeft = $(".single-article-year-timeline").offset().left;
                //             var windowHeight = $(window).height();
                //             var headerHeight = $('#site-header').height();
                //             $('.article-timeline-verticle .swiper-container').height(windowHeight - headerHeight - 40);

                //             $(".article-timeline-verticle").css("left", SingleArticleYearTimelineOffsetLeft);
                //             $(".article-timeline-verticle").css("position", "fixed");

                //             var SingleArticleYearTimelineContainerOffsetTop = $(".article-timeline-verticle .swiper-container").offset().top;
                //             var SingleArticleYearTimelineContainerHeight = $(".article-timeline-verticle .swiper-container").height();
                //             var SingleArticleYearTimelineContainerOffsetBottom = SingleArticleYearTimelineContainerOffsetTop + SingleArticleYearTimelineContainerHeight;

                //             // console.log(SingleArticleYearTimelineContainerOffsetBottom + " SingleArticleYearTimelineContainerOffsetBottom");

                //             var SingleArticleCoverOffsetTop = $(".single-article-cover").offset().top;
                //             var SingleArticleCoverHeight = $(".single-article-cover").height();
                //             var SingleArticleCoverOffsetBottom = SingleArticleCoverOffsetTop + SingleArticleCoverHeight;

                //             // console.log(SingleArticleCoverOffsetBottom + " SingleArticleCoverOffsetBottom");
                            
                //             $(window).scroll(function () {
                //                 if($(".single-article-wrap").length){
                //                     var windowScroll = $(this).scrollTop();
                //                     // SingleArticleOffsetTop = $(".single-article-wrap").offset().top;
                //                     var SingleArticleOffsetBottomScroll = SingleArticleCoverOffsetBottom - windowScroll;
                //                     // console.log(SingleArticleOffsetBottomScroll + " SingleArticleOffsetBottomScroll");
                //                     // console.log(SingleArticleYearTimelineContainerOffsetBottom + " SingleArticleYearTimelineContainerOffsetBottom");

                //                     if(SingleArticleOffsetBottomScroll <= SingleArticleYearTimelineContainerOffsetBottom){
                //                         // console.log("hii");
                //                         $(".article-timeline-verticle").css("position", "absolute");
                //                         $(".article-timeline-verticle").css("bottom", 60);
                //                         $(".article-timeline-verticle").css("left", 0);
                //                     } else {
                //                         // console.log("else");
                //                         $(".article-timeline-verticle").removeAttr("style");
                //                         $(".article-timeline-verticle").css("left", SingleArticleYearTimelineOffsetLeft);
                //                         $(".article-timeline-verticle").css("position", "fixed");
                //                     }
                //                 }
                //             });
                //         }
                //     });
                    
                // },200);

                if (window.matchMedia("(min-width: 992px)").matches) {
                    setTimeout(function(){
                        var windowHeight = $(window).height();
                        var headerHeight = $('#site-header').height();
                        $('.article-timeline-verticle .swiper-container').height(windowHeight - headerHeight - 40);
                        
                        let articleYearLineTl = new TimelineLite({ paused:true });
                        articleYearLineTl
                            .fromTo(".single-article-timeline", 0.5, { autoAlpha: 0, height: 0 }, { autoAlpha: 1, height: "100%" }, 0.2)
                            .play();

                        let articleYearDotTl = new TimelineLite({ paused:true });
                        articleYearDotTl
                            .staggerFromTo(".article-timeline-verticle .swiper-slide", 0.5, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.2, "+=0.5");
                        
                        let articleYearTl = new TimelineLite({ paused:true });
                        articleYearTl
                            .staggerFromTo(".article-timeline-verticle .swiper-slide .single-article-timeline-year", 0.5, { autoAlpha: 0, x: -30 }, { autoAlpha: 1, x: 5 }, 0.2, "+=0.5");
                            
                            articleYearDotTl.play();
                            articleYearTl.play();
                            swiperSlideFun();
                    },700);
                } else {
                    swiperSlideFun();
                }

                function swiperSlideFun(){
                    setTimeout(function(){
                        var activeYearIndex = $(".current-year").index();
                        swiper1.slideTo(activeYearIndex, 1000, false);
                    },2000);
                }
            }
        }
    }, [swiper1]);
    const data = useStaticQuery(graphql`
        query artArticleYearDetailsQuery {
            allArticledetailsJson {
                edges {
                    node {
                        year
                        id
                        article_slug
                    }
                }
            }
        }
    `);
    
    const activeYear = props.currentyear;
    const articleYearData = data.allArticledetailsJson.edges.map((edge) => {
        return activeYear === edge.node.year ? <div className="current-year" data-key={edge.node.id} key={edge.node.id}>{edge.node.year === edge.node.article_slug ? <div className="single-article-timeline-year"><AniLink fade duration={2} to={"/" + edge.node.article_slug} style={{backgroundImage: `url(${edge.node.featuredimg})`}}>{edge.node.year}</AniLink></div> : <div className="single-article-timeline-year">{edge.node.year}</div>}</div> : edge.node.article_slug !== "" ? <div key={edge.node.id}><div className="single-article-timeline-year"><AniLink fade duration={2} to={"/" + edge.node.article_slug} style={{backgroundImage: `url(${edge.node.featuredimg})`}}>{edge.node.year}</AniLink></div></div> : <div key={edge.node.id}><div className="single-article-timeline-year">{edge.node.year}</div></div>
    });
    
    const params = {
        spaceBetween: 40,
        slidesPerView: 'auto',
        direction: 'horizontal',
        freeMode: true,
        freeModeMomentum: false,
        freeModeMomentumBounce: false,
        simulateTouch: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        mousewheel: {
            releaseOnEdges: true,
        },
        breakpoints: {
            992: {
                direction: 'vertical'
            }
        }
    }
    // setTimeout(function(){
    //     activeYearKey = $(".current-year").data("key");
    // },200);
    return (
        // <Swiper getSwiper={updateSwiper1} activeSlideKey={activeYearKey} {...params}>
        <Swiper getSwiper={updateSwiper1} {...params}>
            {articleYearData}
        </Swiper>
    )
}

export default ArticleYearSlider
