import React, { useEffect } from "react"
import Layout from "./layout"
import SEO from "./seo"
import ArticleYearSlider from './articleYearSlider'
import Swiper from 'react-id-swiper'
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { Tween, Timeline } from 'react-gsap';
import { Container, Row, Col } from 'react-bootstrap';
import { Controller, Scene } from 'react-scrollmagic';
import AniLink from "gatsby-plugin-transition-link/AniLink"

const SingleArticle = ({ pageContext }) => {
    useEffect(() => {
        // Article Video
        setTimeout(function(){
            if ($(".article-videoLink").length > 0) {
                $('.article-videoLink').click(function () {
                    var _videow = $(".embed-responsive").width();
                    var _videoh = $(".embed-responsive").height();
                    var video = '<div class="embed-responsive embed-responsive-16by9"><iframe id="modal-video" src="' + $(this).attr('data-video') + '" height="' + _videoh + '" width="' + _videow + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowfullscreen></iframe></div>';
                    $(this).parent('.embed-responsive').replaceWith(video);
                    return false;
                });
            }
        },500);
        if (typeof window !== `undefined`) {
            setTimeout(function(){
                $('html, body').animate({scrollTop:0}, 1000, function(){
                    // Sticky Sidebar
                    if (window.matchMedia("(min-width: 992px)").matches) {
                        // var SingleArticleOffsetTop;
                        // var SingleArticleYearTimelineOffsetTop = $(".single-article-year-timeline").offset().top;
                        var SingleArticleYearTimelineOffsetLeft = $(".single-article-year-timeline").offset().left;
                        var windowHeight = $(window).height();
                        var headerHeight = $('#site-header').height();
                        $('.article-timeline-verticle .swiper-container').height(windowHeight - headerHeight - 40);

                        $(".article-timeline-verticle").css("left", SingleArticleYearTimelineOffsetLeft);
                        $(".article-timeline-verticle").css("position", "fixed");

                        var SingleArticleYearTimelineContainerOffsetTop = $(".article-timeline-verticle .swiper-container").offset().top;
                        var SingleArticleYearTimelineContainerHeight = $(".article-timeline-verticle .swiper-container").height();
                        var SingleArticleYearTimelineContainerOffsetBottom = SingleArticleYearTimelineContainerOffsetTop + SingleArticleYearTimelineContainerHeight;

                        // console.log(SingleArticleYearTimelineContainerOffsetBottom + " SingleArticleYearTimelineContainerOffsetBottom");

                        var SingleArticleCoverOffsetTop = $(".single-article-cover").offset().top;
                        var SingleArticleCoverHeight = $(".single-article-cover").height();
                        var SingleArticleCoverOffsetBottom = SingleArticleCoverOffsetTop + SingleArticleCoverHeight;

                        // console.log(SingleArticleCoverOffsetBottom + " SingleArticleCoverOffsetBottom");
                        
                        $(window).scroll(function () {
                            if($(".single-article-wrap").length){
                                var windowScroll = $(this).scrollTop();
                                // SingleArticleOffsetTop = $(".single-article-wrap").offset().top;
                                var SingleArticleOffsetBottomScroll = SingleArticleCoverOffsetBottom - windowScroll;
                                // console.log(SingleArticleOffsetBottomScroll + " SingleArticleOffsetBottomScroll");
                                // console.log(SingleArticleYearTimelineContainerOffsetBottom + " SingleArticleYearTimelineContainerOffsetBottom");

                                if(SingleArticleOffsetBottomScroll <= SingleArticleYearTimelineContainerOffsetBottom){
                                    // console.log("hii");
                                    $(".single-article-cover-wrap .article-timeline-verticle").css("position", "absolute");
                                    $(".single-article-cover-wrap .article-timeline-verticle").css("bottom", 60);
                                    $(".single-article-cover-wrap .article-timeline-verticle").css("left", 0);
                                } else {
                                    // console.log("else");
                                    $(".single-article-cover-wrap .article-timeline-verticle").removeAttr("style");
                                    $(".single-article-cover-wrap .article-timeline-verticle").css("left", SingleArticleYearTimelineOffsetLeft);
                                    $(".single-article-cover-wrap .article-timeline-verticle").css("position", "fixed");
                                }
                            }
                        });
                    }
                });
                
            },200);
        }
    }, []);

    const params_explore = {
        spaceBetween: 15,
        slidesPerView: 'auto',
        freeMode: true,
        freeModeMomentum: false,
        freeModeMomentumBounce: false,
        simulateTouch: true,
        mousewheel: {
            releaseOnEdges: true,
        },
        breakpoints: {
            992: {
                spaceBetween: 40
            }
        },
        on: {
            beforeInit: function(){
                var containerOffsetLeft = $(".single-article-cover > .container .single-article-timeline").offset().left;
                this.prependSlide('<div class="swiper-slide"></div>');
                $(".explore-slider .swiper-slide:first-child").width(containerOffsetLeft - params_explore.spaceBetween);
            },
            resize:  function(){
                var containerOffsetLeft = $(".single-article-cover > .container .single-article-timeline").offset().left;
                $(".explore-slider .swiper-slide:first-child").width(containerOffsetLeft - params_explore.spaceBetween);
            }
        }
    }
    const articleMilestones = pageContext.explore_section === "enable" && pageContext.explore_milestones.map((explore_milestone) => {
        return <div key={explore_milestone.article_slug}>
            <div className="explore-milestone-item">
                <div className="explore-milestone-img">
                    <AniLink fade duration={2} to={"/" + explore_milestone.article_slug}>
                        <img src={explore_milestone.article_thumbnail} alt={explore_milestone.article_title} />
                    </AniLink>
                </div>
                <h3 className="explore-milestone-item-title"><AniLink fade duration={2} to={"/" + explore_milestone.article_slug}>{explore_milestone.article_title}</AniLink></h3>
                <p>{explore_milestone.article_excerpt}</p>
            </div>
        </div>
    });

    return (
        <Layout logoType={pageContext.article_bg_color}>
            <SEO title={pageContext.title} />
            <Controller>
                <Scene
                duration={300}
                >
                    {(progress) => (
                        <>
                            <Timeline totalProgress={progress} paused
                                target={
                                    <div className="single-article-img" style={{backgroundImage:'url('+ pageContext.imagebig +')'}} alt={pageContext.title} />
                                }
                            >
                                <Tween
                                from={{ opacity: 1 }}
                                to={{ opacity: 0 }}
                                />
                            </Timeline>
                            <div className="single-article single-article-cover-wrap">
                                <div className={"single-article-cover " + pageContext.article_bg_color + " " + pageContext.article_text_color + " " + pageContext.article_gallery_title_color + " " + pageContext.article_timeline_color}>
                                    <Container>
                                        <Row>
                                            <Col className="single-article-year-timeline" lg={{span: 4}} xl={{span: 5}}>
                                                <div className="article-timeline-verticle">
                                                    <ArticleYearSlider currentyear={pageContext.year} />
                                                    <div className="single-article-timeline"></div>
                                                </div>
                                            </Col>
                                            <Col className={"single-article-wrap " + (pageContext.imagebig ? "" : "mt-150")} lg={{span: 8, offset: 4}} xl={{span: 7, offset: 5}}>
                                                <Timeline totalProgress={progress} paused
                                                    target={
                                                        <Row>
                                                            <Col sm={{span: 6, offset: 6}}>
                                                                {pageContext.short_description && <div className="single-article-short-desc" dangerouslySetInnerHTML={{ __html: pageContext.short_description }} />}
                                                            </Col>
                                                        </Row>
                                                    }
                                                >
                                                    <Tween
                                                    from={{ opacity: 1 }}
                                                    to={{ opacity: 0 }}
                                                    />
                                                </Timeline>
                                                <h1 className="page-title">{pageContext.title}</h1>
                                                <div className="article_below_title_description" dangerouslySetInnerHTML={{ __html: pageContext.below_title_description }} />
                                                <div dangerouslySetInnerHTML={{ __html: pageContext.description }} />

                                                {pageContext.gallery_link === "" && <div className="article_gallery_sec">
                                                    {pageContext.gallery_title !== undefined && <h4 className="article_gallery_title"><span>GALLERY: </span>{pageContext.gallery_title}</h4>}
                                                    {pageContext.image != null && <div className="article_gallery_link_img">
                                                        <img src={pageContext.image} alt={pageContext.gallery_title} />
                                                    </div>}
                                                    {pageContext.gallery_caption != null && <div className="article_gallery_caption"><span>Gallery caption: </span>{pageContext.gallery_caption}</div>}
                                                </div>}
                                                
                                                {pageContext.gallery_link != "" && <div className="article_gallery_sec">
                                                    {pageContext.gallery_title != null && <h4 className="article_gallery_title"><span>GALLERY: </span>{pageContext.gallery_title}</h4>}
                                                    {pageContext.image != null && <div className="article_gallery_link_img">
                                                        <img src={pageContext.image} alt={pageContext.gallery_title} />
                                                        <AniLink className="btn primary-btn" fade duration={2} to={"/" + pageContext.gallery_link}>VIEW GALLERY</AniLink>
                                                    </div>}
                                                    {pageContext.gallery_caption != null && <div className="article_gallery_caption"><span>Gallery caption: </span>{pageContext.gallery_caption}</div>}
                                                </div>}

                                                {pageContext.video_link != null && <div className="article_video_sec">
                                                    {pageContext.video_title != null && <h4 className="article_video_title"><span>WATCH: </span>{pageContext.video_title}</h4>}
                                                    {pageContext.video_poster != null && <div className="article_video_link_img">
                                                        {/* <img src={pageContext.video_poster} alt={pageContext.video_title} /> */}
                                                        <div className="embed-responsive embed-responsive-16by9">
                                                            <img className="img-fluid" src={pageContext.video_poster} alt={pageContext.video_title} />
                                                            <a href="#!" className="article-videoLink" data-video={pageContext.video_link}>PLAY VIDEO</a> 
                                                        </div>
                                                    </div>}
                                                    {pageContext.video_caption != null && <div className="article_video_caption"><span>Video caption: </span>{pageContext.video_caption}</div>}
                                                </div>}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                {pageContext.explore_section === "enable" && <div className="explore-sec">
                                    <h2 className="page-title text-center">Explore</h2>
                                    <div className="explore-slider">
                                        <Swiper {...params_explore}>
                                            {articleMilestones}
                                        </Swiper>
                                    </div>
                                </div>}
                            </div>
                        </>
                    )}
                </Scene>
            </Controller>
        </Layout>
    )
}

export default SingleArticle
